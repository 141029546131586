import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { env } from 'utils/env'
import urlConfig from 'constants/chatConfig'
import { getEnvs } from './functions'
import ChatLoggedIn from './ChatLoggedIn'
import ChatAnonymous from './ChatAnonymous'

function ChatContainer() {
  const {
    botAvatarImage,
    botInitials,
    displayHeader,
    forceAutoscrollOnNewMessage,
    hideNewConversationButton,
    hideUploadButton,
    isAnonymous,
    soundUrl
  } = useMemo(() => getEnvs(env), [])

  const { i18n } = useTranslation()

  const displayChatbotName = useMemo(() => {
    const names = i18n.languages.reduce(
      (acc, lang) => ({
        ...acc,
        [lang]: env.REACT_APP_CHATBOT_NAME
      }),
      { default: env.REACT_APP_CHATBOT_NAME }
    )
    return names
  }, [i18n.languages])

  const headerFollowupText = useMemo(() => {
    const texts = i18n.languages.reduce(
      (acc, lang) => ({
        ...acc,
        [lang]: env[`REACT_APP_CHATBOT_HEADER_SUBTEXT_${lang?.toUpperCase()}`] ?? env.REACT_APP_CHATBOT_HEADER_SUBTEXT
      }),
      { default: env.REACT_APP_CHATBOT_HEADER_SUBTEXT }
    )
    return texts
  }, [i18n.languages])
  //<TableSortLabel

  return isAnonymous ? (
    <ChatAnonymous
      isAnonymous={isAnonymous}
      urlConfig={urlConfig}
      displayHeader={displayHeader}
      displayChatbotName={displayChatbotName}
      headerFollowupText={headerFollowupText}
      hideUploadButton={hideUploadButton}
      hideNewConversationButton={hideNewConversationButton}
      botAvatar={{
        image: botAvatarImage,
        initials: botInitials
      }}
      soundUrl={soundUrl}
      forceAutoscrollOnNewMessage={forceAutoscrollOnNewMessage}
      isDevelopment={false}
      headerChatVersion={'0.0.0'}
      emojiSet={false}
    />
  ) : (
    <ChatLoggedIn
      isAnonymous={isAnonymous}
      urlConfig={urlConfig}
      displayHeader={displayHeader}
      displayChatbotName={displayChatbotName}
      headerFollowupText={headerFollowupText}
      hideUploadButton={hideUploadButton}
      hideNewConversationButton={hideNewConversationButton}
      botAvatar={{
        image: botAvatarImage,
        initials: botInitials
      }}
      soundUrl={soundUrl}
      forceAutoscrollOnNewMessage={forceAutoscrollOnNewMessage}
      isDevelopment={false}
      headerChatVersion={'0.0.0'}
      emojiSet={false}
    />
  )
}

export default ChatContainer
