import React from 'react'
import PropTypes from 'prop-types'

const WebchatHeader = ({ displayChatbotName, headerFollowupText, locale }) => {
  const name = displayChatbotName[locale] || displayChatbotName.default
  const follow = headerFollowupText[locale] || headerFollowupText.default
  return (
    <div className='chatbot-top'>
      <span>
        <strong>{name}</strong>
        {follow}
      </span>
      {/* <i className="icon-settings"></i>
      <i className="icon-minimize"></i> */}
    </div>
  )
}

WebchatHeader.propTypes = {
  displayChatbotName: PropTypes.object.isRequired,
  headerFollowupText: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
}

export default WebchatHeader
