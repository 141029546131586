import { ApolloProvider } from '@apollo/client'
import React from 'react'
import PropTypes from 'prop-types'
import { useOidcUser, OidcUserStatus } from '@axa-fr/react-oidc-context'

import { getApolloClient } from './client'
import { Authenticating } from 'components/login/Authenticating'

export function AuthApolloProvider({ children }) {
  const { oidcUserLoadingState } = useOidcUser()

  switch (oidcUserLoadingState) {
    case OidcUserStatus.Loading:
      return Authenticating
    case OidcUserStatus.LoadingError:
      return <p>Fail to load user information</p>
    default:
      return <ApolloProvider client={getApolloClient()}>{children}</ApolloProvider>
  }
}

AuthApolloProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default AuthApolloProvider
