import {
  DIRECT_LINE_INCOMING_ACTIVITY,
  DIRECT_LINE_POST_ACTIVITY_FULFILLED,
  WEBCHAT_SET_SUGGESTED_ACTIONS,
  WEBCHAT_SUBMIT_SEND_BOX,
  WEB_CHAT_SEND_FILES
} from './activityNames'

const list = [
  DIRECT_LINE_POST_ACTIVITY_FULFILLED,
  WEBCHAT_SET_SUGGESTED_ACTIONS,
  DIRECT_LINE_INCOMING_ACTIVITY,
  WEBCHAT_SUBMIT_SEND_BOX,
  WEB_CHAT_SEND_FILES
]

export default list
