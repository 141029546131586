import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import getAuthenticationConfiguration from 'utils/auth/authConfig'
import { OidcProvider as DefaultProvider, useOidc } from '@axa-fr/react-oidc-context'
import { Authenticating } from 'components/login/Authenticating'
import { CallbackPage } from 'components/login/CallbackPage'
import { NotAuthenticated } from 'components/login/NotAuthenticated'
import { SessionExpired } from 'components/login/SessionExpired'
import { Forbidden } from '@bit/totalsoft_oss.react-mui.kit.core'
import { defaultConfigurationName } from 'constants/oidcProvider'

const CallbackSuccess = () => <p>Callback success</p>

const AuthenticationProvider = props => {
  const { children } = props
  const configuration = getAuthenticationConfiguration()

  return (
    <DefaultProvider
      configuration={configuration}
      configurationName={defaultConfigurationName}
      notAuthenticated={NotAuthenticated}
      loadingComponent={CallbackPage}
      sessionLostComponent={SessionExpired}
      authenticating={Authenticating}
      callbackSuccessComponent={CallbackSuccess}
      callbackErrorComponent={Forbidden}
    >
      <Reader>{children}</Reader>
    </DefaultProvider>
  )
}

AuthenticationProvider.propTypes = { children: PropTypes.element.isRequired }

// const userSignedOut = async () => {
//   const userManager = getUserManager()
//   await userManager.removeUser()
//   //state must NOT be undefined
//   //see https://github.com/AxaGuilDEv/react-oidc/blob/e18a4428959e611e0317d8bb9cf659acc2f78785/packages/context/src/Callback/Callback.container.tsx#L6
//   //it happens when IDS CookieMaxAge > 0
//   await userManager.signinRedirect({
//     state: {
//       url: '/'
//     }
//   })
// }

const Reader = props => {
  const { children } = props
  const { events } = useOidc() //no events

  useEffect(() => {
    if (!events) {
      return
    }

    // events.addUserSignedOut(userSignedOut)
    // events.addSilentRenewError(userSignedOut)

    // return () => {
    //   events.removeUserSignedOut(userSignedOut)
    //   events.removeSilentRenewError(userSignedOut)
    // }
  }, [events])

  return children
}
Reader.propTypes = { children: PropTypes.element.isRequired }

export default AuthenticationProvider
