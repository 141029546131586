import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import appStyle from 'assets/jss/components/appStyle'
import cx from 'classnames'
const { env } = require('utils/env')

import Sidebar from './layout/Sidebar'
import Header from './layout/Header'
// import Footer from './layout/Footer'

import AppRoutes from 'routes/AppRoutes'

import { ToastContainer, CheckInternetConnection } from '@bit/totalsoft_oss.react-mui.kit.core'

const useStyles = makeStyles(appStyle)
const isWeb = () => window.matchMedia('(min-width: 480px)')?.matches

// Modified for Anonymous Webchat
const displaySidebar = JSON.parse(env.REACT_APP_WEBCHAT_DISPLAY_SIDEBAR)
const displayHeader = JSON.parse(env.REACT_APP_WEBCHAT_DISPLAY_OUTER_HEADER)

function App(props) {
  const mainPanelRef = useRef()
  const classes = useStyles()
  const { location } = props
  const { i18n } = useTranslation()

  const [drawerOpen, setDrawerOpen] = useState(isWeb())
  window.onresize = _e => setDrawerOpen(isWeb())

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(!drawerOpen)
  }, [drawerOpen])

  const handleCloseDrawer = useCallback(() => {
    if (!drawerOpen) return
    setDrawerOpen(false)
  }, [drawerOpen])

  const changeLanguage = useCallback(
    lng => {
      i18n.changeLanguage(lng.target.value)
    },
    [i18n]
  )

  useEffect(() => {
    mainPanelRef.current.scrollTop = 0
  }, [location.pathname])

  const mainPanel =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: !drawerOpen
    })

  return (
    <div className={classes.wrapper}>
      {displaySidebar && <Sidebar closeDrawer={handleCloseDrawer} changeLanguage={changeLanguage} drawerOpen={drawerOpen} />}
      <div className={mainPanel} ref={mainPanelRef}>
        {displayHeader && <Header drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} displayMinimizeButton={displaySidebar} />}
        <AppRoutes />
        {/* <Footer fluid /> */}
      </div>
      <ToastContainer />
      <CheckInternetConnection />
    </div>
  )
}

App.propTypes = {
  location: PropTypes.object.isRequired
}

export default App
