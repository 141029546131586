// import React from 'react'
// import Chat from '@material-ui/icons/Chat'
// import Settings from '@material-ui/icons/Settings'

// let menuItems = [
//   { icon: <Chat />, text: 'NavBar.Chat', path: '/chat', name: 'Chat' },
//   { icon: <Settings />, text: 'NavBar.Settings', path: '/settings', name: 'Settings' }
// ]
let menuItems = []

export default menuItems
