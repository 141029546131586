import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { List, ListItem, ListItemText, ListItemIcon, makeStyles, Tooltip } from '@material-ui/core'

import userMenuStyle from 'assets/jss/components/userMenuStyle'
import cx from 'classnames'
import LanguageSelector from './LanguageSelector'
import avatar_default from 'assets/img/default-avatar.png'
import { useTranslation } from 'react-i18next'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import { useOidcUser, useOidc } from '@axa-fr/react-oidc-context'
import userMenuConfig from 'constants/userMenuConfig'
import UserMenuItem from './UserMenuItem'
import { useLocation } from 'react-router-dom'
import { getUserName } from 'utils/auth/functions'

const useStyles = makeStyles(userMenuStyle)

function UserMenu({ drawerOpen, avatar, language, changeLanguage }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const { oidcUser } = useOidcUser()
  const { logout } = useOidc()

  const activeRoute = useCallback(routeName => location.pathname.indexOf(routeName) > -1, [location.pathname])
  const userMenuItems = userMenuConfig

  const logoutAction = useCallback(
    e => {
      e.preventDefault()
      logout()
    },
    [logout]
  )

  let userName = getUserName(oidcUser)

  const itemText =
    classes.itemText +
    ' ' +
    cx({
      [classes.itemTextMini]: !drawerOpen
    })

  const displayName = userName
  return (
    <List className={classes.menuList}>
      {/* <ListItem className={classes.item + ' ' + classes.userItem}> */}
      <ListItem className={classes.itemLink}>
        <ListItemIcon className={classes.itemIcon}>
          <img src={avatar ? avatar : avatar_default} className={classes.photo} alt='...' />
        </ListItemIcon>
        <ListItemText primary={displayName} disableTypography={true} className={itemText} />
      </ListItem>
      <List className={classes.list + classes.collapseList}>
        {userMenuItems.map((userMenu, key) => {
          return <UserMenuItem key={key} userMenu={userMenu} drawerOpen={drawerOpen} activeRoute={activeRoute} />
        })}
        {oidcUser && (
          <Tooltip disableHoverListener={drawerOpen} title={t('Tooltips.Logout')}>
            <ListItem className={classes.collapseItem}>
              <NavLink to={'/'} className={classes.itemLink} onClick={logoutAction}>
                <ListItemIcon className={classes.itemIcon}>
                  <PowerSettingsNew />
                </ListItemIcon>
                <ListItemText primary={t('Tooltips.Logout')} disableTypography={true} className={itemText} />
              </NavLink>
            </ListItem>
          </Tooltip>
        )}
        <ListItem className={classes.selectorItem}>
          <LanguageSelector language={language} changeLanguage={changeLanguage} drawerOpen={drawerOpen} />
        </ListItem>
      </List>
      {/* </ListItem> */}
    </List>
  )
}

UserMenu.propTypes = {
  avatar: PropTypes.string,
  drawerOpen: PropTypes.bool.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired
}

export default UserMenu
