import { createDirectLine } from 'botframework-webchat'

export const getDirectLine = async (userID, username, getNewConversation, tokenUrl, token) => {
  let headers = {
    'Content-Type': 'application/json'
  }
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }
  return await fetch(tokenUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      userId: userID,
      username,
      getNewConversation
    })
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        console.error(data.error)
      } else {
        // eslint-disable-next-line no-unused-vars
        const { token, streamUrl, conversationId } = data
        return createDirectLine({ token })
      }
    })
}

// For generating an anonymous user token
export const getDirectLineAnonymous = async tokenUrl => {
  let headers = {
    'Content-Type': 'application/json',
    'Content-Length': 0
  }
  return await fetch(tokenUrl, {
    method: 'POST',
    headers: headers
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        console.error(data.error)
      } else {
        // eslint-disable-next-line no-unused-vars
        const { token, streamUrl, conversationId } = data
        return createDirectLine({ token })
      }
    })
}

export const sendFiles = async (dispatch, action, uploadUrl, token = null) => {
  dispatch({
    type: 'WEB_CHAT/SEND_TYPING'
  })
  const attachmentInfos = await Promise.all(
    action.payload.files.map(({ name, url: localBlobUrl, thumbnail, size }) => {
      return uploadFile(name, localBlobUrl, uploadUrl, token).then(fileUrl => {
        let attachment = {
          contentUrl: fileUrl,
          name
        }
        if (thumbnail) {
          const contentType = extractContentTypesFromBase64(thumbnail)
          attachment = {
            ...attachment,
            contentType,
            thumbnailUrl: thumbnail
          }
        }

        return { attachment, size }
      })
    })
  )

  const mappedAttachmentInfos = {
    attachmentsWebchat: attachmentInfos.map(({ attachment }) => attachment),
    channelData: {
      attachmentSizes: attachmentInfos.map(({ size }) => size)
    }
  }
  const activity = {
    name: 'webchat/upload',
    type: 'message',
    ...mappedAttachmentInfos
  }
  dispatch({
    type: 'DIRECT_LINE/POST_ACTIVITY',
    meta: { method: 'keyboard' },
    payload: {
      activity
    }
  })
}

export const uploadFile = async (name, localBlobUrl, uploadUrl, token = null) => {
  let headers = {
    'Content-Type': 'application/octet-stream'
  }
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }

  const arrayBuffer = await fetch(localBlobUrl).then(res => res.arrayBuffer())
  return await fetch(uploadUrl + '?name=' + name, {
    method: 'POST',
    headers: headers,
    body: arrayBuffer
  })
    .then(res => res.json())
    .then(({ uploadUrl }) => {
      return uploadUrl
    })
}

export const getUsernameInitialsForAvatar = username =>
  username
    ?.split(' ', 2)
    .map(x => x.charAt(0))
    .join('')
    .toUpperCase() || 'US'

export const moveAttachmentsWebchatToAttachments = activity => {
  activity.attachments = activity.attachmentsWebchat
  delete activity.attachmentsWebchat
  delete activity.name
}

export const extractContentTypesFromBase64 = baseString => baseString.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]

export const defaultStyleOptions = {
  autoScrollSnapOnPage: true
}

export const createStyleOptions = (styleOptions = {}) => ({ ...defaultStyleOptions, ...styleOptions })

export const forceScrollDown = document => {
  const htmlTranscriptActivities = document.querySelectorAll('.webchat__basic-transcript__activity')
  if (htmlTranscriptActivities && htmlTranscriptActivities.length > 0) {
    const last = htmlTranscriptActivities[htmlTranscriptActivities.length - 1]
    last.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}

export const connectToChatbot = (isAnonymous, fullTokenUrl, userID, username, accessToken, setDirectLine) => {
  const executeFetch = async () => {
    let dl
    if (isAnonymous) {
      dl = await getDirectLineAnonymous(fullTokenUrl)
    } else {
      dl = await getDirectLine(userID, username, false, fullTokenUrl, accessToken)
    }
    setDirectLine(dl)
  }
  executeFetch()
}

export const createConfig = (
  directLine,
  store,
  userID,
  username,
  locale,
  styleSet,
  activityMiddleware,
  botAvatar,
  hideUploadButton,
  customLocalization,
  emojiSet
) => {
  let config = {
    directLine,
    store,
    userID,
    username,
    locale,
    styleSet,
    activityMiddleware,
    styleOptions: {
      adaptiveCardsParserMaxVersion: '1.5'
    }
  }

  if (styleSet) {
    config.styleSet = styleSet
  }

  if (botAvatar) {
    config.styleOptions = {
      ...config.styleOptions,
      botAvatarImage: botAvatar.image,
      botAvatarInitials: botAvatar.initials
    }
  }

  if (hideUploadButton) {
    config.styleOptions.hideUploadButton = hideUploadButton
  }

  if (customLocalization) {
    config.overrideLocalizedStrings = (strings, language) => {
      if (customLocalization[language]) {
        return {
          ...strings,
          ...customLocalization[language]
        }
      } else {
        return strings
      }
    }
  }
  config.styleOptions.emojiSet = emojiSet
  return config
}
