// Modified for Anonymous Webchat
import { env } from 'utils/env'
const displaySidebar = JSON.parse(env.REACT_APP_WEBCHAT_DISPLAY_OUTER_HEADER)

const chatbotMainStyle = _theme => {
  return displaySidebar
    ? {
        container: props => ({
          padding: props.fullWidth ? 0 : '20px 40px',
          height: 'calc(100vh - 67px)'
        })
      }
    : {
        container: props => ({
          padding: props.fullWidth ? 0 : '20px 40px',
          height: 'calc(100vh - 0px)'
        })
      }
}

export default chatbotMainStyle
