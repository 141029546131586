/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import CustomRoute from '../components/routing/CustomRoute'
import ChatbotRoute from 'components/routing/ChatbotRoute'

import ChatContainer from 'features/chat/ChatContainer'
import { Forbidden } from '@bit/totalsoft_oss.react-mui.kit.core'
import { env } from 'utils/env'

export default function AppRoutes() {
  useEffect(() => {
    document.title = env.REACT_APP_TITLE
  })

  return (
    <Switch>
      <ChatbotRoute isPrivate={true} exact path='/' component={ChatContainer} fullWidth={true} />
      <CustomRoute isPrivate={false} exact path='/forbidden' component={Forbidden} />
      <CustomRoute isPrivate={false} render={() => <Redirect to='/'></Redirect>} />
    </Switch>
  )
}
