export const getEnvs = env => ({
  isAnonymous: JSON.parse(env.REACT_APP_WEBCHAT_IS_ANONYMOUS),
  hideUploadButton: JSON.parse(env.REACT_APP_WEBCHAT_HIDE_UPLOAD_BUTTON),
  hideNewConversationButton: JSON.parse(env.REACT_APP_WEBCHAT_HIDE_NEWCONVERSATION_BUTTON),
  displayHeader: JSON.parse(env.REACT_APP_WEBCHAT_DISPLAY_INNER_HEADER),
  botAvatarImage: env.REACT_APP_WEBCHAT_BOT_AVATAR_IMAGE_URL,
  soundUrl: env.REACT_APP_WEBCHAT_SOUND_URL,
  botInitials: env.REACT_APP_CHATBOT_NAME.charAt(0),
  forceAutoscrollOnNewMessage: JSON.parse(env.REACT_APP_WEBCHAT_FORCE_AUTOSCROLL)
})
