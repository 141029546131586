import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import chatbotMainStyle from 'assets/jss/components/chatbotMainStyle'
import { PrivateRoute } from './CustomRoute'

const useStyles = makeStyles(chatbotMainStyle)

function ChatbotRoute({ isPrivate, fullWidth, ...props }) {
  const isAnonymous = JSON.parse(process.env.REACT_APP_WEBCHAT_IS_ANONYMOUS) // Modified for Anonymous Webchat
  const classes = useStyles({ fullWidth })
  return <div className={classes.container}>{isPrivate && !isAnonymous ? <PrivateRoute {...props} /> : <Route {...props} />}</div>
}

ChatbotRoute.defaultProps = {
  isPrivate: true,
  fullWidth: false
}

ChatbotRoute.propTypes = {
  component: PropTypes.func,
  exact: PropTypes.bool,
  isPrivate: PropTypes.bool,
  fullWidth: PropTypes.bool,
  path: PropTypes.string
}

export default ChatbotRoute
